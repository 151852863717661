/*
  To make checkin work you need to activate ssl when fireing up the app

  run `npm run dev -- --https`

  follow the instructions on https://www.gatsbyjs.org/docs/local-https/ for setting up ssl on localhost
*/

import React, { useState, useEffect } from "react";
import { graphql } from "gatsby";
import { Helmet } from "react-helmet";
import { getBlockContent, getBlockImage } from "../utils/helpers";

// Layout
import Layout from "../components/layout/Layout";
// Containers
import RenderRichContent from "../components/containers/RenderRichContent";
import Grid from "../components/primitives/Grid";

const Tickets = ({ data }) => {
  const { pageTitle, shoutOut, metaInfo, title, slug, _rawContent } =
    data?.sanityPages || {};

  const [checkinLoaded, setCheckinLoaded] = useState(false);

  const metaStructure = {
    title: metaInfo?.header || title,
    someTitle: metaInfo?.someHeader || pageTitle,
    description:
      metaInfo?.description || getBlockContent(_rawContent) || shoutOut,
    fullSlug: slug?.current,
    image: metaInfo?.image || getBlockImage(_rawContent)?.image,
    imageAlt: metaInfo?.image
      ? metaInfo?.imageAlt
      : getBlockImage(_rawContent)?.alt,
  };

  useEffect(() => {
    let script = document.createElement("script");
    script.src = "/js/checkin-y-private.js";

    document.head.append(script);

    script.onload = function () {
      setCheckinLoaded(true);
    };

    script.onerror = function () {
      setCheckinLoaded(false);
    };
  }, []);

  useEffect(() => {
    // Change text on Bestill billetter
    if (checkinLoaded) {
      const timer = setInterval(() => {
        const singleBtn = document.querySelector(
          'button.btn.orderMode[value="default"]'
        );
        const groupBtn = document.querySelector(
          'button.btn.orderMode[value="reservation"]'
        );
        if (singleBtn && groupBtn) {
          singleBtn.innerHTML = "Personal tickets";
          groupBtn.innerHTML = "Group tickets";
          return () => clearInterval(timer);
        }
      }, 100);
    }
  }, [checkinLoaded]);

  return (
    <>
      <Helmet>
        <link rel="stylesheet" type="text/css" href={"/styles/checkin.css"} />
      </Helmet>
      <Layout metaInfo={metaStructure}>
        <Grid>
          <div className="col-span-12">
            <h1 className="text-4xl uppercase motion-safe:animate-symbol-bounce-slow">
              {pageTitle}
            </h1>
          </div>
          <div className="col-span-12 lg:col-span-8 xxl:col-span-6">
            {shoutOut && <p className="font-bold">{shoutOut}</p>}
          </div>
        </Grid>
        <RenderRichContent content={_rawContent} />

        {checkinLoaded ? (
          <div id="checkin_registration">&nbsp;</div>
        ) : (
          <p>
            The registration module could not be loaded. Please use another
            browser or turn off your AdBlocker
          </p>
        )}
      </Layout>
    </>
  );
};

export default Tickets;

export const query = graphql`
  query {
    sanityPages(slug: { current: { eq: "tickets-y-private" } }) {
      pageTitle
      _rawContent(resolveReferences: { maxDepth: 10 })
      shoutOut
      slug {
        current
      }
      metaInfo {
        description
        header
        someHeader
        imageAlt
        image {
          asset {
            url
          }
        }
      }
    }
  }
`;
